import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store';
import API from '../../utils/API';
import { Dashboard, DashboardListItem, WidgetGrid } from './types/dashboard';
import {
  RequestStatusEnum,
  ResponseData,
  ResponseDataStatus,
} from '../../types/api';
import { setError } from '../error/errorSlice';
import { PlainObject } from '../../types/common';
import { NewWidgetGridType } from './types/widget';

interface WidgetsDataItem {
  loading: boolean;
  data: any;
}

type WidgetsDataType = Record<string, WidgetsDataItem>;

interface DashboardState {
  list: DashboardListItem[];
  current: Dashboard | null;
  totalPages: number;
  totalElements: number;
  loading: boolean;
  changed: boolean;
  activeWidgetGrid: WidgetGrid | null;
  widgetsData: WidgetsDataType;
  statusMap: PlainObject;
  widgetErrors: { id: string; message: string }[];
}

const initialState: DashboardState = {
  list: [],
  current: null,
  loading: false,
  totalPages: 0,
  totalElements: 0,
  changed: false,
  activeWidgetGrid: null,
  widgetsData: {},
  statusMap: {},
  widgetErrors: [],
};

interface DashboardListResponseResult {
  totalPages: number;
  totalElements: number;
  content: DashboardListItem[];
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    loading: (state: DashboardState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    receiveDashboard: (
      state: DashboardState,
      action: PayloadAction<Dashboard>,
    ) => {
      state.current = action.payload;
      state.changed = false;
    },
    change: (state: DashboardState, action: PayloadAction<Dashboard>) => {
      state.current = action.payload;
      state.changed = true;
    },
    receiveDashboardList: (
      state: DashboardState,
      action: PayloadAction<DashboardListResponseResult>,
    ) => {
      const { totalPages, totalElements, content } = action.payload;

      return { ...state, list: content, totalPages, totalElements };
    },
    receiveWidgetGrid: (
      state: DashboardState,
      action: PayloadAction<WidgetGrid>,
    ) => {
      const widgetGrid = state.current?.widgetGrids.find(
        item => item.id === action.payload.id,
      );

      state.activeWidgetGrid = action.payload;

      if (widgetGrid) {
        widgetGrid.widget = action.payload.widget;
      }
    },
    setWidgetParameter: (
      state: DashboardState,
      action: PayloadAction<{ widgetId: string; data: any }>,
    ) => {
      const { widgetId, data } = action.payload;
      const widgetGrid: WidgetGrid | undefined =
        state.current?.widgetGrids.find(wg => wg.widget.id === widgetId);

      if (widgetGrid) {
        widgetGrid.widget.parameter = data;
      }
    },

    remove: (state: DashboardState, action: PayloadAction<string>) => {
      return {
        ...state,
        list: state.list.filter(dashboard => dashboard.id !== action.payload),
      };
    },

    setActiveWidgetGrid: (
      state: DashboardState,
      action: PayloadAction<WidgetGrid | null>,
    ) => {
      state.activeWidgetGrid = action.payload;
    },

    receiveWidgetsData: (
      state: DashboardState,
      action: PayloadAction<{ widgetId: string; data: WidgetsDataType }>,
    ) => {
      const { widgetId, data } = action.payload;

      if (!state.widgetsData[widgetId]) {
        state.widgetsData[widgetId] = { loading: false, data: null };
      }
      state.widgetsData[widgetId].data = data;
    },

    setWidgetLoading: (
      state: DashboardState,
      action: PayloadAction<{ widgetId: string; loading: boolean }>,
    ) => {
      const { widgetId, loading: l } = action.payload;

      state.widgetsData[widgetId].loading = l;
    },
    setWidgetStatus: (
      state: DashboardState,
      action: PayloadAction<{ widgetId: string; status: RequestStatusEnum }>,
    ) => {
      state.statusMap = {
        ...state.statusMap,
        [action.payload.widgetId]: action.payload.status,
      };
    },
    receivePrintWidgetGrid: (
      state: DashboardState,
      { payload }: PayloadAction<WidgetGrid>,
    ) => {
      state.current = {
        id: '',
        name: '',
        widgetGrids: [
          { ...payload, position: { ...payload.position, x: 0, y: 0 } },
        ],
      };
    },

    setWidgetError: (
      state,
      action: PayloadAction<{ id: string; message: string }>,
    ) => {
      state.widgetErrors.push(action.payload);
    },
    deleteWidgetError: (state, action: PayloadAction<string>) => {
      state.widgetErrors = state.widgetErrors.filter(
        el => el.id !== action.payload,
      );
    },
    reset: () => initialState,
  },
});

export const {
  loading,
  receiveDashboardList,
  receiveDashboard,
  reset,
  change,
  remove,
  setActiveWidgetGrid,
  receiveWidgetsData,
  receiveWidgetGrid,
  setWidgetParameter,
  setWidgetStatus,
  receivePrintWidgetGrid,
  setWidgetError,
  deleteWidgetError,
} = dashboardSlice.actions;

/**
 * Fetch a dashboard by id
 */
export const fetchDashboard =
  (dashboardId: string): AppThunk =>
  async dispatch => {
    dispatch(loading(true));
    try {
      const response = await API.get(`/api/dashboard/${dashboardId}`);

      dispatch(receiveDashboard(response.data.result));
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loading(false));
    }
  };

export const fetchWidget =
  (widgetGridId: string): AppThunk =>
  async dispatch => {
    try {
      const response = await API.get(`/api/dashboard/widget/${widgetGridId}`);

      dispatch(receivePrintWidgetGrid(response.data.result));
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loading(false));
    }
  };

/**
 * Fetch all dashboards
 */
export const fetchDashboardList = (): AppThunk => async dispatch => {
  dispatch(loading(true));
  try {
    const data = {
      page: 0,
      size: 0,
      sort: {
        field: 'name',
        type: 'ASC',
      },
    };
    const response = await API.post<ResponseData<DashboardListResponseResult>>(
      '/api/dashboard/list/user',
      data,
    );

    dispatch(receiveDashboardList(response.data.result));
  } catch (e: any) {
    dispatch(setError(e));
  } finally {
    dispatch(loading(false));
  }
};

export const updateDashboard =
  (dashboard: Dashboard): AppThunk =>
  async dispatch => {
    dispatch(loading(true));
    try {
      await API.put('/api/dashboard', dashboard);
      if (dashboard.id) {
        dispatch(fetchDashboard(dashboard.id));
      }
    } catch (e: any) {
      dispatch(setError(e));
    } finally {
      dispatch(loading(false));
    }
  };

export const addWidget =
  (dashboardId: string, widgetGrid: NewWidgetGridType): AppThunk =>
  async dispatch => {
    try {
      await API.post(`/api/dashboard/${dashboardId}`, widgetGrid);

      dispatch(fetchDashboard(dashboardId));
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const createEmptyDashboard =
  (name: string, successCb?: (id: string) => void): AppThunk =>
  async dispatch => {
    try {
      const data = { name, widgetGrids: [] };
      const response = await API.post('/api/dashboard', data);
      if (typeof successCb === 'function') {
        successCb(response.data.result.id);
      }
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const removeDashboard =
  (dashboardId: string): AppThunk =>
  async dispatch => {
    try {
      await API.delete(`/api/dashboard/${dashboardId}`);
      dispatch(remove(dashboardId));
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

/**
 * Change dashboard item
 *
 * @param dashboard - current dashboard
 * @param widgetGrid - changed dashboard item
 */
export const updateWidgetGrid =
  (dashboard: Dashboard, widgetGrid: WidgetGrid): AppThunk =>
  async dispatch => {
    try {
      const response = await API.put(
        `/api/dashboard/dashboard/${dashboard.id}/widget`,
        widgetGrid,
      );
      dispatch(receiveWidgetGrid(response.data.result));
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

/**
 * Delete widget in dashboard
 *
 * @param {string} dashboardId - current dashboard id
 * @param {string} widgetGridId - deleting item
 */
export const deleteWidget =
  (dashboardId: string, widgetGridId: string): AppThunk =>
  async dispatch => {
    try {
      await API.delete(
        `/api/dashboard/dashboard/${dashboardId}/widget/${widgetGridId}`,
      );
      dispatch(fetchDashboard(dashboardId));
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

export const fetchWidgetData =
  (widgetId: string, tabId?: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(
        setWidgetStatus({ widgetId, status: RequestStatusEnum.LOADING }),
      );

      const response = await API.get<ResponseData>(
        `/api/widget/${widgetId}/data`,
        { params: { tabId } },
      );

      if (response.data.status === ResponseDataStatus.SUCCESS) {
        dispatch(receiveWidgetsData({ widgetId, data: response.data.result }));
        dispatch(
          setWidgetStatus({ widgetId, status: RequestStatusEnum.SUCCEEDED }),
        );
      }
    } catch (e: any) {
      dispatch(setWidgetStatus({ widgetId, status: RequestStatusEnum.FAILED }));
      dispatch(setError(e));
    }
  };

export const updateWidgetParameter =
  (params: { name: string; id: string; value: string | string[] }): AppThunk =>
  async dispatch => {
    const { id, value } = params;

    try {
      dispatch(setWidgetParameter({ widgetId: id, data: value }));
      await API.put(`/api/widget/${id}/changeParameter`, value);
    } catch (e: any) {
      dispatch(setError(e));
    }
  };

// selectors
export const selectDashboardList = (state: RootState): DashboardListItem[] =>
  state.dashboard.list;
export const isDashboardLoading = (state: RootState): boolean =>
  state.dashboard.loading;
export const selectWidgetStatusMap = (state: RootState): PlainObject =>
  state.dashboard.statusMap;
export const selectCurrentDashboard = (state: RootState): Dashboard | null =>
  state.dashboard.current;
export const isDashboardChanged = (state: RootState): boolean =>
  state.dashboard.changed;
export const selectActiveWidgetGrid = (state: RootState): WidgetGrid | null =>
  state.dashboard.activeWidgetGrid;

export type MappedFilters = {
  fieldName: string;
  comparison: string;
  filterValue: string | string[];
}[];

export const getMappedFilters = (
  current: DashboardState['current'],
  widgetId?: string,
) => {
  if (!widgetId) {
    return [];
  }

  if (!current?.widgetGrids) {
    return [];
  }

  const currentConfig = current.widgetGrids.find(i => i.widget.id === widgetId);

  if (!currentConfig) {
    return [];
  }

  const filledFilters = current.widgetGrids.reduce<
    Record<string, string | string[]>
  >((acc, current) => {
    const currentValue = current.widget.parameter;

    if (Array.isArray(currentValue)) {
      if (currentValue.length > 0) {
        return {
          ...acc,
          [current.widget.idName]: currentValue,
        };
      }
    }

    if (typeof currentValue === 'string') {
      if (currentValue) {
        return {
          ...acc,
          [current.widget.idName]: currentValue,
        };
      }
    }

    return acc;
  }, {});

  if (Object.values(filledFilters).flat().length === 0) {
    return [];
  }

  if (currentConfig.widget.component) {
    const widgetParams = JSON.parse(currentConfig.widget.component) as Partial<{
      config: Partial<{
        filters: {
          fieldName: string;
          comparison: string;
          filterValue: string;
        }[];
      }>;
    }>;

    if (widgetParams.config?.filters) {
      const widgetFilters = widgetParams.config.filters.filter(i =>
        i.filterValue.startsWith('@{widget.'),
      );

      const mappedFilters = widgetFilters.reduce<MappedFilters>(
        (acc: any, current) => {
          const filterKey = current.filterValue.split('.')[1].slice(0, -1);
          const filterValue = filledFilters[filterKey];

          if (filterValue) {
            return [
              ...acc,
              {
                ...current,
                filterValue,
              },
            ];
          } else {
            return acc;
          }
        },
        [],
      );

      return mappedFilters;
    }
  }

  return [];
};

export const selectWidgetData = createSelector(
  (state: RootState) => state.dashboard.widgetsData,
  (state: RootState) => state.dashboard.current,
  (_state: RootState, widgetId?: string) => widgetId,
  (widgetsData, current, widgetId) => {
    if (!widgetId) {
      return [];
    }

    const widgetData = widgetsData?.[widgetId]?.data || [];

    if (widgetData.length === 0) {
      return [];
    }

    const mappedFilters = getMappedFilters(current, widgetId);

    if (mappedFilters.length === 0) {
      return widgetData;
    }

    return widgetData.filter((d: any) => {
      return mappedFilters.every(f => {
        const filterValue = f.filterValue;

        return filterValue.includes(String(d[f.fieldName]));
      });
    });
  },
);

export const isWidgetloading = (state: RootState, widgetId: string): boolean =>
  state.dashboard.widgetsData[widgetId].loading;

export const selectWidgetErrors = createSelector(
  (state: RootState) => state.dashboard.widgetErrors,
  (state: RootState, widgetId: string | undefined) => widgetId,
  (widgetErrors, widgetId) => widgetErrors.find(el => el.id === widgetId),
);

export default dashboardSlice.reducer;
