import React, { MouseEvent, memo } from 'react';
import cn from 'classnames';
import { EditOutlined } from '@ant-design/icons';
import ErrorBoundary from '../ErrorBoundary';
import Widget from '../Widget';
import { WidgetGrid } from '../../features/dashboard/types/dashboard';
import styles from './WidgetGridItem.module.less';
import { selectWidgetErrors } from '../../features/dashboard/dashboardSlice';
import { useAppSelector } from '../../store';

interface WidgetGridItemProps {
  widgetGrid: WidgetGrid;
  editable: boolean;
  onSelect: (widgetGrid: WidgetGrid) => void;
  onUpdate: (widgetGrid: WidgetGrid) => void;
  active: boolean;
  onEditBtnClick: (e: MouseEvent) => void;
  onChangeWidgetParameter: (params: {
    name: string;
    id: string;
    value: string | string[];
  }) => void;
  onWidgetActionsClick?: () => void;
}

const WidgetGridItem: React.FC<WidgetGridItemProps> = ({
  widgetGrid,
  editable,
  onSelect,
  active,
  onEditBtnClick,
  onChangeWidgetParameter,
}) => {
  const widgetError = useAppSelector(state =>
    selectWidgetErrors(state, widgetGrid.widget.id),
  );
  const handleWidgetGridClick = () => {
    if (!editable) {
      return;
    }

    onSelect(widgetGrid);
  };

  const isFlat = true;

  return (
    <div
      onClick={handleWidgetGridClick}
      className={cn(styles.container, {
        [styles.bordered]: !isFlat,
        [styles.active]: active,
      })}
    >
      <ErrorBoundary errorMessage={widgetError?.message}>
        <Widget
          widget={widgetGrid.widget}
          onChangeParameter={onChangeWidgetParameter}
        />
      </ErrorBoundary>
      {active && (
        <div className={styles.editButton}>
          <EditOutlined style={{ color: 'white' }} onClick={onEditBtnClick} />
        </div>
      )}
    </div>
  );
};

const MemoizedWidgetGridItem = memo(WidgetGridItem);

export default MemoizedWidgetGridItem;
