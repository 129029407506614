import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import DashboardForm from './DashboardForm';
import { useSelector } from 'react-redux';
import {
  change,
  deleteWidget,
  fetchDashboard,
  isDashboardChanged,
  isDashboardLoading,
  reset,
  selectActiveWidgetGrid,
  selectCurrentDashboard,
  setActiveWidgetGrid,
  updateDashboard,
  updateWidgetGrid,
  updateWidgetParameter,
} from '../../dashboardSlice';
import DashboardLayout from '../../../../components/layout/DashboardLayout';
import WidgetsPanel from '../../../../components/WidgetsPanel';
import { DisplayMode } from '../../types/displayMode';
import { Dashboard as DashboardType, WidgetGrid } from '../../types/dashboard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch } from '../../../../store';

const Dashboard: React.FC = () => {
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const dispatch = useAppDispatch();
  const loading = useSelector(isDashboardLoading);
  const dashboard = useSelector(selectCurrentDashboard);
  const changed = useSelector(isDashboardChanged);
  const activeWidgetGrid = useSelector(selectActiveWidgetGrid);

  const [displayMode, setDisplayMode] = useState(DisplayMode.VIEW);

  useEffect(() => {
    dispatch(fetchDashboard(dashboardId));

    return () => {
      dispatch(reset());
    };
  }, [dispatch, dashboardId]);

  const handleReload = () => {
    dispatch(fetchDashboard(dashboardId));
  };

  const handleSave = () => {
    if (dashboard) {
      dispatch(updateDashboard(dashboard));
    }
  };

  const handleChange = useCallback((_dashboard: DashboardType) => {
    dispatch(change(_dashboard));
  }, []);

  const handleWidgetSelect = useCallback((widgetGrid: WidgetGrid) => {
    dispatch(setActiveWidgetGrid(widgetGrid));
  }, []);

  const handleWidgetUpdate = useCallback(
    (widgetGrid: WidgetGrid) => {
      if (dashboard) {
        dispatch(updateWidgetGrid(dashboard, widgetGrid));
      }
    },
    [dashboard],
  );

  const handleDeleteWidgetGrid = useCallback(
    (widgetGridId: string) => {
      dispatch(deleteWidget(dashboardId, widgetGridId));
    },
    [dashboardId],
  );

  const handleChangeDisplayMode = (mode: DisplayMode) => {
    setDisplayMode(mode);
    if (mode === DisplayMode.VIEW) {
      dispatch(setActiveWidgetGrid(null));
    }
  };

  const handleChangeDashboardTitle = (value: string) => {
    if (dashboard && !isEmpty(value)) {
      dispatch(updateDashboard({ ...dashboard, name: value }));
    }
  };

  const handleChangeWidgetParameter = useCallback(
    (params: { name: string; id: string; value: string | string[] }) => {
      dispatch(updateWidgetParameter(params));
    },
    [],
  );

  const editable = useMemo(
    () => displayMode === DisplayMode.EDIT,
    [displayMode],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <DashboardLayout
        title={dashboard?.name}
        onChangeTitle={handleChangeDashboardTitle}
        toolbar={
          <WidgetsPanel
            onSave={handleSave}
            dashboard={dashboard}
            onReload={handleReload}
            displayMode={displayMode}
            onChangeDisplayMode={handleChangeDisplayMode}
            loading={loading}
            changed={changed}
          />
        }
      >
        {dashboard && (
          <DashboardForm
            dashboard={dashboard}
            loading={loading}
            isEditable={editable}
            onChange={handleChange}
            onSelectWidgetGrid={handleWidgetSelect}
            activeWidgetGrid={activeWidgetGrid}
            onWidgetUpdate={handleWidgetUpdate}
            onDeleteWidgetGrid={handleDeleteWidgetGrid}
            onChangeWidgetParameter={handleChangeWidgetParameter}
          />
        )}
      </DashboardLayout>
    </DndProvider>
  );
};

export default Dashboard;
