import React, { useCallback } from 'react';
import { DatePicker, TimePicker, Space } from 'antd';
import moment, { Moment } from 'moment';
import { DateWidgetConfig } from '../../types/dateTime';
import { widgetDateFormat } from '../../../../utils/dateFormats';

interface DateTimeWidgetProps {
  config: DateWidgetConfig;
  onChange: (data: string) => void;
  parameter: any;
}

const getDate = (params: any): any => {
  if (Array.isArray(params)) {
    const [first] = params;

    return first ? moment(first) : null;
  }

  return params ? moment(params) : null;
};

const DateTimeWidget: React.FC<DateTimeWidgetProps> = ({
  parameter,
  config,
  onChange,
}) => {
  const handleChange = useCallback(
    (value: Moment | null) => {
      onChange(value ? moment(value).toString() : '');
    },
    [onChange],
  );

  const { label, padding } = config;

  const dateMoment = getDate(parameter);

  return (
    <div style={{ display: 'flex', padding: padding || 0 }}>
      <Space>
        {label && <span>{label}</span>}
        <DatePicker
          size="large"
          format={widgetDateFormat}
          defaultValue={dateMoment}
          onChange={handleChange}
        />
        <TimePicker
          size="large"
          defaultValue={dateMoment}
          onChange={handleChange}
        />
      </Space>
    </div>
  );
};

export default DateTimeWidget;
