import React, { useCallback } from 'react';
import { TimePicker, Space } from 'antd';
import moment, { Moment } from 'moment';
import { TimeWidgetConfig } from '../../types/dateTime';

interface TimeWidgetProps {
  parameter: any;
  config: TimeWidgetConfig;
  onChange: (data: string) => void;
}

const getDate = (params: any, format: string): any => {
  if (Array.isArray(params)) {
    const [first] = params;

    return first ? moment(first, format) : null;
  }

  return params ? moment(params, format) : null;
};

const TimeWidget: React.FC<TimeWidgetProps> = ({
  parameter,
  config,
  onChange,
}) => {
  const handleChange = useCallback(
    (value: Moment | null, stringValue: string) => {
      onChange(stringValue);
    },
    [onChange],
  );

  const { label, padding, format = 'HH:mm' } = config;

  const dateMoment = getDate(parameter, format);

  return (
    <div style={{ display: 'flex', padding: padding || 0 }}>
      <Space>
        {label && <span>{label}</span>}
        <TimePicker
          size="large"
          defaultValue={dateMoment}
          onChange={handleChange}
          format={format}
        />
      </Space>
    </div>
  );
};

export default TimeWidget;
